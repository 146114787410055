import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import {
  ChartDataPoint,
  FeedChartDataPoint,
  PriceChartDataPoint,
  MetricsChartType,
  FavoriteList,
  Mall,
  MetricsComparison,
  ProductInfo,
  User,
  ContentChartDataPoint,
  ContentStats,
  Feed,
  FeedTypes,
  Country,
  ProductReview,
  ProductReviewFilter,
  ReviewsByStar,
  ReviewsByCountry,
  TimelineEvent,
  BrandGroup,
  ExportList,
  Subscription,
  ProductOffers,
  AutocompleteSuggestion,
  CategoryTree,
  ProductListFilter,
  ProductListItem,
  Category,
  FilterPreset,
  FilterPresetType,
  FilterPresetInfo,
  ISO8601DateString,
  ProductDescriptionVersion,
  StartExportResponse,
  BusinessUserStore,
  BusinessSalesStats,
  BusinessSalesLostProductStats,
  BusinessSalesProductList,
  BusinessSalesBrand,
  BusinessReviewsStopword,
  BusinessReviewsTemplate,
  BusinessReviewsRule,
  BusinessProductNoStats,
  BusinessReviewsBrand,
  BusinessReviewsOnboarding,
  BusinessReviewsStopwordFilter,
  BusinessReviewsTemplateFilter,
  BusinessReviewsRuleFilter,
  NewUser,
  FormValidationErrors,
  PromoCode,
  CategoryListFilter,
  CategoryStatBrand,
  CategoryStatMerchant,
  CategoryPriceRange,
  ListingDTO,
  CategoryStatCharts,
  CategoryList,
  CategoryStatChartsQuery,
  BusinessSalesProductListFilter,
  OfferChartType,
  PrimeCostResponse,
  KeywordListFilter,
  Keyword,
  KeywordBrand,
  KeywordMerchant,
  AiFeatureName,
  FeedType,
  ReverseFeedTypes,
  QuantileResponse,
  QuantileRequest,
  SellersWorkScheme,
  SubscriptionTariffs,
  SubscriptionRequest,
  SubscriptionResponse,
  RegisterResponse,
  RegisterSendAuthCodeResponse,
  LoginData,
  LoginResponse,
  LoginSubmitAuthCodeResponse,
  RecoverySendAuthCodeResponse,
  RecoveryResetPasswordResponse,
  CompanyContactRequest,
  BLArticleList,
  BLArticle,
  PersonContactRequest,
  WarehouseChartType,
  BidderCampaignInfo,
  BidderCampaignListFilter,
  BidderCampaignListItem,
  BidderCampaignStat,
  BidderCampaignBidderStat,
  BidderCampaignBidDistribution,
  VariantChartType,
  ProductListItemsSummary,
  BidderCampaignUpdateInfo,
  BidderCampaignKeywords,
  BidderCampaignKeywordsUpdate,
  BidderCampaignBidDistributionRequest,
  BidderRegion,
  BidderProductCurrentPosition,
  KeywordStatValue,
  BidderCampaignProduct,
  BidderCampaignBidPrediction,
  BusinessSalesChartData,
  SupplyRecommendationReport,
  SupplyRecommendationRequest,
  SupplyRecommendationSettings,
  SupplyRecommendationAvailableSettings,
  ProductNameVersion,
  ProductVariationAggregates,
  FeedChartPositionsRequest,
  FeedChartPositions,
  SubjectTree,
  Subject,
  MarketplaceCommission,
  WarehouseCoefficient,
  BidderSyncResponse,
  BidderCampaignStatSummary,
  InventoryType,
  ProductTag,
  BusinessOrdersListItem,
  BusinessOrdersListFilter,
  UserKeywordList,
  UserKeywordStats,
  UserKeyword,
  BusinessUserApiToken,
  ExternalAdvertisingPublication,
  ExternalAdvertisingPublicationListFilter,
  ExternalAdvertisingSearch,
  ExternalAdvertisingSearchListFilter,
  ExternalAdvertisingPublicationCount,
  SupplyBookingOrder,
  SupplyBookingOrderPlanRequest,
  BidderCampaignBudgetReplenishmentLog,
  BidderCampaignBudgetReplenishment,
  BidderCampaignBudgetReplenishmentUpdate,
  BusinessSalesProductChartData,
  BusinessSalesProductChartRequest,
  RepricerAddRequest,
  RepricerUpdateRequest,
  RepricerListItem,
  RepricerListFilter,
  RepricerGetChangesRequest,
  RepricerChartData,
  RepricerChangelog,
  RepricerProductInfo,
  RepricerGetProductsInfoRequest,
  RepricerConfig,
  RepricerScenario,
} from 'lib/dto'
import { ContextDTO } from 'lib/contextCreation'
import { Theme } from '../configs/theme'
import { ActionpayAction, actionpayGoalIds } from 'lib/dto'
import { MallCode } from 'configs/malls'

export class Context {
  isAuthorized: boolean
  malls: Mall[]
  currentMall: Mall
  theme: Theme
  user: User
  ip: string
  userAgent: string
  private cookie: string | null
  private api: AxiosInstance

  constructor(ctx: ContextDTO, api?: AxiosInstance) {
    this.isAuthorized = ctx.isAuthorized
    this.currentMall = ctx.currentMall
    this.malls = ctx.malls
    this.theme = ctx.theme
    this.user = ctx.user as User
    this.cookie = ctx.cookie
    this.ip = ctx.ip
    this.userAgent = ctx.userAgent

    if (api) {
      this.api = api
      return
    }

    const isServerSide = typeof window === 'undefined'

    const apiBaseURL = isServerSide
      ? `${
          ctx.host.includes('localhost') || ctx.host.match(/\d/) || process.env.FORCE_HTTP === '1'
            ? 'http'
            : 'https'
        }://${ctx.host}/api/next/`
      : '/api/next/'

    this.api = axios.create({
      baseURL: apiBaseURL,
      headers: {
        'x-auth': ctx.isAuthorized,
        ...(isServerSide && ctx.cookie && { cookie: ctx.cookie }),
        ...(isServerSide && ctx.ip && { 'x-forwarded-for': ctx.ip }),
        ...(isServerSide && ctx.userAgent && { 'user-agent': ctx.userAgent }),
      },
      withCredentials: true,
      validateStatus: (status) => (status >= 200 && status < 300) || status === 429,
    })
  }

  private get = async <TResponse>(strings: TemplateStringsArray, ...params: any[]) => {
    const route = strings.reduce((acc, val, i) => acc + val + (params[i] ?? ''), '')

    const apiResponse = await this.api.get<TResponse>(route)

    return apiResponse.data
  }

  private post = <TResponse>(strings: TemplateStringsArray, ...params: any[]) => {
    const route = strings.reduce((acc, val, i) => acc + val + (params[i] ?? ''), '')

    return async (data?: object, config?: AxiosRequestConfig) => {
      const apiResponse = await this.api.post<object, AxiosResponse<TResponse>>(route, data, config)
      return apiResponse.data
    }
  }

  getCurrentUser = async () => {
    const res = await this.api.get<User>('/user/current/', {
      headers: { ...(this.cookie && { Cookie: this.cookie }), 'x-auth': true },
    })

    return [res.data, res.headers['set-cookie'] as string | string[]] as const
  }

  getMallList = () => this.get<Mall[]>`/mall/list/`

  getMallInfo = (code: string) => this.get<Mall>`/mall/${code}/info/`

  getProductInfo = (mall: string, id: string) => this.get<ProductInfo>`/product/${mall}/info/${id}/`

  getFavoriteLists = () => this.get<FavoriteList[]>`/favorites/${this.currentMall.code}/lists/`

  getFavoriteListsForProduct = (id: number) =>
    this.get<FavoriteList[]>`/favorites/${this.currentMall.code}/product/${id}/lists/`

  createFavoriteList = (name: string) =>
    this.post<FavoriteList>`/favorites/${this.currentMall.code}/list/add/`({ name })

  renameFavoriteList = (id: number, name: string) =>
    this.post<FavoriteList>`/favorites/${this.currentMall.code}/list/${id}/rename/`({ name })

  deleteFavoriteList = (id: number) =>
    this.post<FavoriteList>`/favorites/${this.currentMall.code}/list/${id}/delete/`({})

  addToFavoriteList = (listId: number, id: number) =>
    this.post<FavoriteList>`/favorites/${this.currentMall.code}/list/${listId}/product/add/`({
      productId: id,
    })

  removeFromFavoriteList = (listId: number, id: number) =>
    this.post<FavoriteList>`/favorites/${this.currentMall.code}/list/${listId}/product/remove/`({
      productId: id,
    })

  getFavoriteListProducts = (id: number, filter: ProductListFilter, abortSignal?: AbortSignal) =>
    this.post<
      ListingDTO<ProductListItem>
    >`/favorites/${this.currentMall.code}/list/${id}/products/list/`(filter, {
      signal: abortSignal,
    })

  startFavoriteListProductsExport = (id: number, filter: ProductListFilter) =>
    this
      .post<StartExportResponse>`/favorites/${this.currentMall.code}/list/${id}/products/list/export/`(
      filter
    )

  getMetricsAggregate = (id: number, period: number) =>
    this
      .get<MetricsComparison>`/product/${this.currentMall.code}/aggregations-period/${id}/days/${period}/`

  getProductMetricsAggregate = ({
    productId,
    from,
    to,
    inventoryType,
  }: {
    productId: number
    from?: ISO8601DateString
    to?: ISO8601DateString
    inventoryType?: InventoryType
  }) =>
    this.post<MetricsComparison>`/product/${this.currentMall.code}/aggregations/${productId}/`({
      from: from ?? null,
      to: to ?? null,
      inventoryType,
    })

  getVariationAggregates = ({
    productId,
    from,
    to,
  }: {
    productId: number
    from?: ISO8601DateString
    to?: ISO8601DateString
  }) =>
    this.post<
      ProductVariationAggregates[]
    >`/product/${this.currentMall.code}/variations-aggregations/${productId}/`({
      from: from ?? null,
      to: to ?? null,
    })

  getChartData = <T extends MetricsChartType>(
    id: number,
    chartType: T,
    from?: ISO8601DateString,
    to?: ISO8601DateString,
    inventoryType?: InventoryType
  ) =>
    this.post<
      T extends 'prices' ? PriceChartDataPoint[] : ChartDataPoint[]
    >`/product/${this.currentMall.code}/single-chart/${id}/${chartType}/`({
      from,
      to,
      inventoryType,
    })

  getOfferChartData = <T extends OfferChartType>({
    variationId,
    merchantId,
    chartType,
  }: {
    variationId: number
    merchantId: number
    chartType: T
  }) =>
    this.post<
      ChartDataPoint[]
    >`/product/${this.currentMall.code}/offer-chart/${variationId}/${merchantId}/${chartType}/`({})

  getWarehouseChartData = <T extends WarehouseChartType>({
    productId,
    warehouseId,
    chartType,
    variationId,
    from,
    to,
  }: {
    productId: number
    chartType: T
    warehouseId?: string
    variationId?: number
    from?: ISO8601DateString
    to?: ISO8601DateString
  }) =>
    this.post<
      ChartDataPoint[]
    >`/product/${this.currentMall.code}/warehouse-chart/${productId}/${chartType}/`({
      from: from ?? null,
      to: to ?? null,
      variationId: variationId ?? null,
      warehouseId: warehouseId ?? null,
    })

  getVariantChartData = <T extends VariantChartType>({
    productId,
    variationId,
    chartType,
    from,
    to,
  }: {
    productId: number
    variationId: number
    chartType: T
    from?: ISO8601DateString
    to?: ISO8601DateString
  }) =>
    this.post<
      ChartDataPoint[]
    >`/product/${this.currentMall.code}/variation-chart/${productId}/${variationId}/${chartType}/`({
      from: from ?? null,
      to: to ?? null,
    })

  getContentChart = (id: number) =>
    this.post<ContentChartDataPoint[]>`/product/${this.currentMall.code}/content/${id}/chart/`({})

  getContentStats = (id: number) =>
    this.get<ContentStats>`/product/${this.currentMall.code}/content/${id}/stat/`

  getFeedsList = (id: number) =>
    this.get<Feed[]>`/product/${this.currentMall.code}/feeds/${id}/list/`

  getFeedChart = (
    productId: number,
    feedId: number,
    feedType: keyof typeof FeedTypes,
    from?: ISO8601DateString,
    to?: ISO8601DateString
  ) =>
    this.post<
      FeedChartDataPoint[]
    >`/product/${this.currentMall.code}/feeds/${productId}/feed/${feedId}/${feedType}/chart/`(
      from && to
        ? {
            from,
            to,
          }
        : {}
    )

  getFeedChartPositions = (productId: number, request: FeedChartPositionsRequest) =>
    this.post<FeedChartPositions[]>`/product/${this.currentMall.code}/feeds/${productId}/charts/`(
      request
    )

  startFeedsChartsExport = (
    productId: number,
    request: Omit<FeedChartPositionsRequest, 'feedsIds'>
  ) =>
    this
      .post<StartExportResponse>`/product/${this.currentMall.code}/feeds/${productId}/charts/export/`(
      request
    )

  getFeedProducts = (
    feedType: FeedType,
    feedId: number,
    filter: ProductListFilter,
    signal: AbortSignal
  ) =>
    this.post<
      ListingDTO<ProductListItem> & { feedId: number }
    >`/feeds/${this.currentMall.code}/${feedId}/${ReverseFeedTypes[feedType]}/products/`(filter, {
      signal,
    })

  getCountryList = () => this.get<Country[]>`/countries/${this.currentMall.code}/list/`

  getReviews = (productId: number, filter: ProductReviewFilter = {}) =>
    this.post<ProductReview[]>`/product/${this.currentMall.code}/reviews/${productId}/list/`(filter)

  getReviewsByStar = (productId: number) =>
    this.get<ReviewsByStar[]>`/product/${this.currentMall.code}/reviews/${productId}/distribution/`

  getReviewsByCountry = (productId: number) =>
    this.get<ReviewsByCountry[]>`/product/${this.currentMall.code}/reviews/${productId}/geo/list/`

  getProductTimeline = (productId: number) =>
    this.get<TimelineEvent[]>`/product/${this.currentMall.code}/events/${productId}/`

  updateUserFullname = (name: string) => this.post<User>`/user/update-info/`({ fullName: name })

  updateUserPassword = (oldPassword: string, newPassword: string) =>
    this.post`/user/change-password/`({
      oldPassword,
      newPassword,
    })

  getBrandGroups = () => this.get<BrandGroup[]>`/user/brands-groups/`

  getUserSubscription = () => this.get<Subscription>`/user/subscription/active/`

  setUserSubscription = (subscriptionId: number, mallId: number, newState: boolean) =>
    this.post`/subscription/${subscriptionId}/mall/${mallId}/renew/${
      newState ? 'enable' : 'disable'
    }/`({})

  getUserTariffs = () => this.get<SubscriptionTariffs>`/subscription/tariffs/`

  relinkCard = (subscriptionId: number) =>
    this.post<{ url: string }>`/subscription/${subscriptionId}/relink-card/`({})

  createSubscription = (request: SubscriptionRequest) =>
    this.post<SubscriptionResponse>`/subscription/create/`(request)

  requestSubscriptionPayment = (request: SubscriptionRequest) =>
    this.post<[]>`/subscription/request-payment-on-account/`(request)

  getExportList = (page: number, pageSize: number) =>
    this.get<ExportList>`/reports/list/?page=${page}&pageSize=${pageSize}`

  removeReport = (id: number) => this.post`/reports/${id}/hide/`({})

  getProductOffers = (id: number) =>
    this.get<ProductOffers>`/product/${this.currentMall.code}/offers/${id}/`

  getEntityNamesByIds = <T extends 'brands' | 'merchants' | 'categories' | 'subjects'>(
    entity: T,
    ids: number[],
    abortSignal?: AbortSignal
  ) =>
    this.post<
      T extends 'categories'
        ? Category[]
        : T extends 'subjects'
        ? Subject[]
        : AutocompleteSuggestion[]
    >`/${entity}/${this.currentMall.code}/ids/`(
      {
        ids,
      },
      { signal: abortSignal }
    )

  getNameSuggestion = (
    entity: 'brands' | 'merchants' | 'categories',
    name?: string,
    limit?: number
  ) =>
    this.post<AutocompleteSuggestion[]>`/${entity}/${this.currentMall.code}/suggest/`({
      name,
      limit,
    })

  getProductTagsList = () => this.get<ProductTag[]>`/tags/${this.currentMall.code}/list/`

  getProductList = (mall: string, filter: ProductListFilter, abortSignal?: AbortSignal) =>
    this.post<ListingDTO<ProductListItem>>`/products/${mall}/list/`(filter, {
      signal: abortSignal,
    })

  getProductListItemsSummary = (filter: ProductListFilter, abortSignal?: AbortSignal) =>
    this.post<ProductListItemsSummary>`/products/${this.currentMall.code}/list/summary/`(filter, {
      signal: abortSignal,
    })

  getPresetsByType = (type: FilterPresetType) =>
    this.get<FilterPresetInfo[]>`/presets/${this.currentMall.code}/list/${type}/`

  getFilterPreset = (id: number) => this.get<FilterPreset>`/presets/${id}/`

  addFilterPreset = (name: string, type: FilterPresetType, data: string) =>
    this.post<FilterPreset>`/presets/${this.currentMall.code}/add/`({ name, type, data })

  deleteFilterPreset = (id: number) => this.post<[]>`/presets/${id}/delete/`({})

  getProductNameVersions = (id: number) =>
    this.get<ProductNameVersion[]>`/product/${this.currentMall.code}/name/${id}/versions/`

  getProductDescriptionVersions = (id: number) =>
    this.get<string[]>`/product/${this.currentMall.code}/description/${id}/versions/`

  getProductDescription = (id: number, versions: string[]) =>
    this.post<
      ProductDescriptionVersion[]
    >`/product/${this.currentMall.code}/description/${id}/texts/`({ versions })

  //
  //
  // EXPORTS METHODS
  //

  startProductListExport = (mall: string, filter: ProductListFilter) =>
    this.post<StartExportResponse>`/products/${mall}/list/export/`(filter)

  startFeedListExport = (productId: number) =>
    this
      .post<StartExportResponse>`/product/${this.currentMall.code}/feeds/${productId}/list/export/`(
      {}
    )

  startFeedExport = (productId: number, feedId: number, feedType: number) =>
    this
      .post<StartExportResponse>`/product/${this.currentMall.code}/feeds/${productId}/feed/${feedId}/${feedType}/chart/export/`(
      {}
    )

  startReviewsExport = (productId: number, filter: ProductReviewFilter = {}) =>
    this
      .post<StartExportResponse>`/product/${this.currentMall.code}/reviews/${productId}/list/export/`(
      filter
    )

  startChartsExport = (productId: number) =>
    this.post<StartExportResponse>`/product/${this.currentMall.code}/charts/${productId}/export/`(
      {}
    )

  startOffersExport = (productId: number) =>
    this.post<StartExportResponse>`/product/${this.currentMall.code}/offers/${productId}/export/`(
      {}
    )

  startOfferChartExport = (variantId: number, merchantId: number) =>
    this
      .post<StartExportResponse>`/product/${this.currentMall.code}/offer-charts/${variantId}/${merchantId}/export/`(
      {}
    )

  startWarehouseChartExport = ({
    productId,
    warehouseId,
    variationId,
    from,
    to,
  }: {
    productId: number
    warehouseId?: string
    variationId?: number
    from?: ISO8601DateString
    to?: ISO8601DateString
  }) =>
    this
      .post<StartExportResponse>`/product/${this.currentMall.code}/warehouse-charts/${productId}/export/`(
      {
        from: from ?? null,
        to: to ?? null,
        variationId: variationId ?? null,
        warehouseId: warehouseId ?? null,
      }
    )

  startEventsExport = (productId: number) =>
    this.post<StartExportResponse>`/product/${this.currentMall.code}/events/${productId}/export/`(
      {}
    )

  startBusinessProductListExport = (
    filter: BusinessSalesProductListFilter,
    sellersWorkScheme: SellersWorkScheme,
    keyId?: number
  ) =>
    this.post<StartExportResponse>`/mall-analytics/dashboard/${this.currentMall.code}/list/export/`(
      { ...filter, sellersWorkScheme, marketplaceAccountId: keyId }
    )

  startCategoryStatExport = (mall: string, filter: CategoryListFilter) =>
    this.post<StartExportResponse>`/category-stat/${mall}/list/export/`({
      from: filter.from,
      to: filter.to,
      categoryId: filter.categoryId,
      brandsIds: filter.brandsIds,
      merchantsIds: filter.merchantsIds,
      inventoryType: filter.inventoryType,
    })

  startKeywordListExport = (filter: KeywordListFilter) =>
    this.post<StartExportResponse>`/keywords/${this.currentMall.code}/list/export/`(filter)

  startKeywordProductListExport = (feedId: number, feedType: 1 | 2 | 3 | 4 | 6) =>
    this
      .post<StartExportResponse>`/feeds/${this.currentMall.code}/${feedId}/${feedType}/products/export/`(
      {}
    )

  //
  //
  // CATEGORIES METHODS
  //

  getCategoryTree = () => this.get<CategoryTree[]>`/categories/${this.currentMall.code}/tree/`

  getSubjectTree = () => this.get<SubjectTree[]>`/subjects/${this.currentMall.code}/tree/`

  getCategoriesIds = (ids: number[]) =>
    this.post<CategoryTree[]>`/categories/${this.currentMall.code}/ids/`({ ids })

  getCategoriesByBrands = (brands: number[]) =>
    this.post<number[]>`/categories/${this.currentMall.code}/brands-categories/`({ ids: brands })

  getCategoriesLastUpdated = () =>
    this.get<{
      lastDate: ISO8601DateString | null
    }>`/category-stat/${this.currentMall.code}/last-date/`

  getCategoryStats = (mall: string, filter: CategoryListFilter, abortSignal?: AbortSignal) =>
    this.post<CategoryList>`/category-stat/${mall}/list/`(
      {
        from: filter.from,
        to: filter.to,
        categoryId: filter.categoryId,
        brandsIds: filter.brandsIds,
        merchantsIds: filter.merchantsIds,
        sort: 'gmv_desc',
        inventoryType: filter.inventoryType,
      },
      {
        signal: abortSignal,
      }
    )

  getCategoryStatCharts = (query: CategoryStatChartsQuery) =>
    this.post<CategoryStatCharts>`/category-stat/${this.currentMall.code}/chart/`(query)

  getCategoryPriceDistribution = (categoryId: number, filter: CategoryListFilter) =>
    this.post<
      CategoryPriceRange[]
    >`/category-stat/${this.currentMall.code}/${categoryId}/price-distribution/`({
      from: filter.from,
      to: filter.to,
      brandsIds: filter.brandsIds,
      merchantsIds: filter.merchantsIds,
      inventoryType: filter.inventoryType,
    })

  //
  //
  // MERCHANT AND BRAND LIST METHODS
  //

  getBrandStatList = (filter: CategoryListFilter, signal: AbortSignal) =>
    this.post<ListingDTO<CategoryStatBrand>>`/brand-stat/${this.currentMall.code}/list/`(filter, {
      signal,
    })

  getMerchantStatList = (filter: CategoryListFilter, signal: AbortSignal) =>
    this.post<ListingDTO<CategoryStatMerchant>>`/merchant-stat/${this.currentMall.code}/list/`(
      filter,
      { signal }
    )

  startBrandStatListExport = (filter: CategoryListFilter) =>
    this.post<StartExportResponse>`/brand-stat/${this.currentMall.code}/list/export/`(filter)

  startMerchantStatListExport = (filter: CategoryListFilter) =>
    this.post<StartExportResponse>`/merchant-stat/${this.currentMall.code}/list/export/`(filter)

  getBrandStatChart = (query: CategoryStatChartsQuery) =>
    this.post<CategoryStatCharts>`/brand-stat/${this.currentMall.code}/chart/`(query)

  getMerchantStatChart = (query: CategoryStatChartsQuery) =>
    this.post<CategoryStatCharts>`/merchant-stat/${this.currentMall.code}/chart/`(query)

  //
  //
  // KEYWORDS METHODS
  //

  getKeywordList = (filter: KeywordListFilter, signal: AbortSignal) =>
    this.post<ListingDTO<Keyword>>`/keywords/${this.currentMall.code}/list/`(filter, { signal })

  getKeywordBrands = (feedId: number) =>
    this.get<KeywordBrand[]>`/keywords/${this.currentMall.code}/brands/${feedId}/`

  getKeywordMerchants = (feedId: number) =>
    this.get<KeywordMerchant[]>`/keywords/${this.currentMall.code}/merchants/${feedId}/`

  getKeywordQuantiles = () =>
    this.post<QuantileResponse>`/keywords/${this.currentMall.code}/quantiles/`({
      metrics: ['competition'],
      quantiles: [0.05, 0.1, 0.2, 0.5],
    } as QuantileRequest)

  getKeywordCharts = (feedId: number) =>
    this.get<KeywordStatValue[]>`/keywords/${this.currentMall.code}/${feedId}/charts/`

  getUserKeywordList = ({
    page,
    pageSize,
    query,
  }: {
    page: number
    pageSize: number
    query: string
  }) =>
    this.get<UserKeywordList>`/user-keywords/list/?page=${page}&pageSize=${pageSize}&query=${query}`

  getUserKeywordStats = () => this.get<UserKeywordStats>`/user-keywords/stats/`

  addUserKeyword = (mall: MallCode, text: string) =>
    this.post<UserKeyword>`/user-keywords/${mall}/add/`({ text })

  deleteUserKeyword = (id: number) => this.post<[]>`/user-keywords/${id}/delete/`({})

  //
  // BUSINESS API-KEYS METHODS
  //

  getBusinessAccounts = () =>
    this.get<BusinessUserStore[]>`/mall-analytics/account/${this.currentMall.code}/list/`

  decodeBusinessApiKey = (newKey: string, marketplaceSellerAccountId?: string) =>
    this.post<
      Pick<
        BusinessUserApiToken,
        'expiresAt' | 'availableApiAccesses' | 'availableServices' | 'readOnly' | 'testScopeOnly'
      >
    >`/mall-analytics/account/${this.currentMall.code}/decode-api-key/`({
      newKey,
      marketplaceSellerAccountId,
    })

  createBusinessAccount = (value: {
    name: string
    key: string
    marketplaceSellerAccountId?: string
  }) =>
    this.post<BusinessUserStore>`/mall-analytics/account/${this.currentMall.code}/create/`({
      name: value.name,
      key: value.key,
      marketplaceSellerAccountId: value.marketplaceSellerAccountId,
    })

  updateBusinessAccount = (apiKeyId: number, value: { name: string }) =>
    this.post<BusinessUserStore>`/mall-analytics/account/${apiKeyId}/update/`({
      name: value.name,
    })

  deleteBusinessAccount = (apiKeyId: number) =>
    this.post`/mall-analytics/account/${apiKeyId}/delete/`({})

  changeBusinessAccountApiKey = (apiKeyId: number, newKey: string) =>
    this.post<BusinessUserStore>`/mall-analytics/account/${apiKeyId}/change-api-key/`({ newKey })

  enableBusinessReviews = (apiKeyId: number) =>
    this.post<BusinessUserStore>`/autoreplier/account/${this.currentMall.code}/${apiKeyId}/enable/`(
      {}
    )

  disableBusinessReviews = (apiKeyId: number) =>
    this
      .post<BusinessUserStore>`/autoreplier/account/${this.currentMall.code}/${apiKeyId}/disable/`(
      {}
    )

  getBusinessProductList = (marketplaceAccountId?: number, search?: string[]) =>
    this.post<
      BusinessProductNoStats[]
    >`/mall-analytics/dashboard/${this.currentMall.code}/products/`({
      marketplaceAccountId,
      search,
    })

  getBusinessReviewsBrands = (apiKeyId: number) =>
    this.get<BusinessReviewsBrand[]>`/autoreplier/brand/${this.currentMall.code}/${apiKeyId}/list/`

  //
  //
  // BUSINESS SALES METHODS
  //

  getBusinessSalesStats = (filter: {
    from: string
    to: string
    brandId: number[]
    sellersWorkScheme: SellersWorkScheme
    marketplaceAccountId?: number
  }) =>
    this.post<BusinessSalesStats>`/mall-analytics/dashboard/${this.currentMall.code}/stats/`(filter)

  getBusinessSalesChartData = (filter: {
    from: string
    to: string
    brandId: number[]
    sellersWorkScheme: SellersWorkScheme
    marketplaceAccountId?: number
  }) =>
    this.post<BusinessSalesChartData[]>`/mall-analytics/dashboard/${this.currentMall.code}/graph/`(
      filter
    )

  getBusinessSalesProductChartData = (filter: BusinessSalesProductChartRequest) =>
    this
      .post<BusinessSalesProductChartData>`/mall-analytics/dashboard/${this.currentMall.code}/product/charts/`(
      filter
    )

  getBusinessSalesLostProductStats = (
    brandId: number[],
    sellersWorkScheme: SellersWorkScheme,
    marketplaceAccountId?: number
  ) =>
    this.post<
      BusinessSalesLostProductStats[]
    >`/mall-analytics/dashboard/${this.currentMall.code}/products/lost-stats/`({
      brandId,
      sellersWorkScheme,
      marketplaceAccountId,
    })

  getBusinessSalesProducts = (filter: BusinessSalesProductListFilter) =>
    this.post<BusinessSalesProductList>`/mall-analytics/dashboard/${this.currentMall.code}/list/`(
      filter
    )

  getBusinessSalesBrands = (apiKeyId?: number) =>
    this.post<BusinessSalesBrand[]>`/mall-analytics/dashboard/${this.currentMall.code}/brands/`({
      marketplaceAccountId: apiKeyId,
    })

  checkNullPrimeCosts = (options: {
    from: ISO8601DateString
    to: ISO8601DateString
    marketplaceAccountId?: number
  }) =>
    this.post<{
      hasNullPrimeCosts: boolean
    }>`/mall-analytics/prime-cost/${this.currentMall.code}/check/`(options)

  updatePrimeCost = ({
    productSku,
    primeCost,
    size,
  }: {
    productSku: string
    primeCost: number
    size?: string
  }) =>
    this
      .post<PrimeCostResponse>`/mall-analytics/prime-cost/${this.currentMall.code}/article/${productSku}/update/`(
      { primeCost, size }
    )

  deletePrimeCost = ({ productSku, size }: { productSku: string; size?: string }) =>
    this.post<
      []
    >`/mall-analytics/prime-cost/${this.currentMall.code}/article/${productSku}/delete/`({ size })

  deleteAllPrimeCosts = (marketplaceAccountId?: number) =>
    this.post<[]>`/mall-analytics/prime-cost/${this.currentMall.code}/delete-all/`({
      marketplaceAccountId,
    })

  uploadPrimeCostFile = (file: File) => {
    const formData = new FormData()
    formData.append('file', file)
    return this.api.post<[] | FormValidationErrors>(
      `/mall-analytics/prime-cost/${this.currentMall.code}/upload/`,
      formData
    )
  }

  //
  //
  // BUSINESS ORDERS METHODS
  //

  getBusinessOrdersList = (filter: BusinessOrdersListFilter, signal: AbortSignal) =>
    this.post<
      ListingDTO<BusinessOrdersListItem>
    >`/mall-analytics/orders/${this.currentMall.code}/list/`(filter, { signal })

  //
  //
  // BUSINESS STOPWORDS METHODS
  //

  getBusinessReviewsStopwordList = (sort: string, filter: BusinessReviewsStopwordFilter) =>
    this.post<BusinessReviewsStopword[]>`/autoreplier/stopword/${this.currentMall.code}/list/`({
      sort,
      text: filter.text,
      marketplaceAccountId: filter.marketplaceAccountId,
    })

  createBusinessReviewsStopword = (value: BusinessReviewsStopword, list: string[]) =>
    this.post`/autoreplier/stopword/${this.currentMall.code}/${value.marketplaceAccountId}/create/`(
      {
        list: list,
      }
    )

  updateBusinessReviewsStopword = (apiKeyId: number, value: BusinessReviewsStopword) =>
    this.post`/autoreplier/stopword/${this.currentMall.code}/${apiKeyId}/${value.id}/update/`({
      text: value.text,
      marketplaceAccountId: value.marketplaceAccountId,
    })

  daleteBusinessReviewsStopword = (apiKeyId: number, stopwordId: number) =>
    this.post`/autoreplier/stopword/${this.currentMall.code}/${apiKeyId}/${stopwordId}/delete/`({})

  //
  //
  // BUSINESS TEMPLATES METHODS
  //

  getBusinessReviewsTemplateList = (sort: string, filter: BusinessReviewsTemplateFilter) =>
    this.post<BusinessReviewsTemplate[]>`/autoreplier/template/${this.currentMall.code}/list/`({
      sort,
      title: filter.title,
      productsId: filter.productsId,
      brandsId: filter.brandsId,
      marketplaceAccountId: filter.marketplaceAccountId,
    })

  createBusinessReviewsTemplate = (value: BusinessReviewsTemplate) =>
    this.post`/autoreplier/template/${this.currentMall.code}/${value.marketplaceAccountId}/create/`(
      {
        title: value.title,
        text: value.text,
        productsId: value.productsId,
        brandsId: value.brandsId,
      }
    )

  updateBusinessReviewsTemplate = (apiKeyId: number, value: BusinessReviewsTemplate) =>
    this.post`/autoreplier/template/${this.currentMall.code}/${apiKeyId}/${value.id}/update/`({
      title: value.title,
      text: value.text,
      marketplaceAccountId: value.marketplaceAccountId,
      productsId: value.productsId,
      brandsId: value.brandsId,
    })

  deleteBusinessReviewsTemplate = (apiKeyId: number, templatedId: number) =>
    this.post`/autoreplier/template/${this.currentMall.code}/${apiKeyId}/${templatedId}/delete/`({})

  //
  //
  // BUSINESS RULES METHODS
  //

  getBusinessReviewsRuleList = (sort: string, filter: BusinessReviewsRuleFilter) =>
    this.post<BusinessReviewsRule[]>`/autoreplier/rule/${this.currentMall.code}/list/`({
      sort,
      title: filter.title,
      productId: filter.productId,
      brandId: filter.brandId,
      templateId: filter.templateId,
      enabled: filter.enabled,
      marketplaceAccountId: filter.marketplaceAccountId,
    })

  createBusinessReviewsRule = (value: BusinessReviewsRule) =>
    this.post`/autoreplier/rule/${this.currentMall.code}/${value.marketplaceAccountId}/create/`({
      title: value.title,
      productsId: value.productsId,
      brandsId: value.brandsId,
      templatesId: value.templatesId,
      accountableRate: value.accountableRate,
      enabled: value.enabled,
      replyToEmptyReviews: value.replyToEmptyReviews,
      replyToNonEmptyReviews: value.replyToNonEmptyReviews,
    })

  updateBusinessReviewsRule = (apiKeyId: number, value: BusinessReviewsRule) =>
    this.post`/autoreplier/rule/${this.currentMall.code}/${apiKeyId}/${value.id}/update/`({
      title: value.title,
      productsId: value.productsId,
      brandsId: value.brandsId,
      templatesId: value.templatesId,
      accountableRate: value.accountableRate,
      enabled: value.enabled,
      marketplaceAccountId: value.marketplaceAccountId,
      replyToEmptyReviews: value.replyToEmptyReviews,
      replyToNonEmptyReviews: value.replyToNonEmptyReviews,
    })

  daleteBusinessReviewsRule = (apiKeyId: number, ruledId: number) =>
    this.post`/autoreplier/rule/${this.currentMall.code}/${apiKeyId}/${ruledId}/delete/`({})

  enableBusinessReviewsRule = (apiKeyId: number, ruledId: number) =>
    this.post`/autoreplier/rule/${this.currentMall.code}/${apiKeyId}/${ruledId}/enable/`({})

  disableBusinessReviewsRule = (apiKeyId: number, ruledId: number) =>
    this.post`/autoreplier/rule/${this.currentMall.code}/${apiKeyId}/${ruledId}/disable/`({})

  //
  //
  // BUSINESS REVIEWS OTHER METHODS
  //

  getBusinessReviewsOnboarding = () =>
    this.get<BusinessReviewsOnboarding>`/storage/reviewsOnboarding/get/`

  setBusinessReviewsOnboarding = (value: any) =>
    this.post<BusinessReviewsOnboarding>`/storage/reviewsOnboarding/set/`({ ...value })

  //
  //
  // BUSINESS BIDDER METHODS
  //

  getBidderCampaignList = (filter: BidderCampaignListFilter, signal: AbortSignal) =>
    this.post<
      ListingDTO<BidderCampaignListItem> & BidderSyncResponse
    >`/bidder/campaign/${this.currentMall.code}/list/`(filter, { signal })

  getBidderCampaignInfo = (apiKeyId: number, campaignId: number) =>
    this
      .get<BidderCampaignInfo>`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/info/`

  getBidderCampaignKeywords = (apiKeyId: number, campaignId: number) =>
    this
      .get<BidderCampaignKeywords>`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/keywords/`

  syncBidderCampaignKeywords = (apiKeyId: number, campaignId: number) =>
    this
      .post<BidderSyncResponse>`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/keywords/sync/`(
      {}
    )

  getBidderCampaignAvailableProducts = (apiKeyId: number, campaignId: number) =>
    this.get<
      BidderCampaignProduct[]
    >`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/products-to-add/`

  updateBidderCampaignKeywords = (
    apiKeyId: number,
    campaignId: number,
    update: BidderCampaignKeywordsUpdate
  ) =>
    this
      .post<BidderCampaignKeywords>`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/keywords/update/`(
      update
    )

  updateBidderCampaignInfo = (
    apiKeyId: number,
    campaignId: number,
    update: BidderCampaignUpdateInfo
  ) =>
    this
      .post<BidderCampaignInfo>`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/update/`(
      update
    )

  getBidderCampaignStat = (apiKeyId: number, campaignId: number) =>
    this.post<
      BidderCampaignStat[]
    >`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/stat/`({})

  getBidderCampaignStatSummary = (
    apiKeyId: number,
    campaignId: number,
    body: { from?: ISO8601DateString; to?: ISO8601DateString }
  ) =>
    this
      .post<BidderCampaignStatSummary>`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/stat/summary/`(
      body
    )

  getBidderCampaignBidderStat = (
    apiKeyId: number,
    campaignId: number,
    body: { from?: ISO8601DateString; to?: ISO8601DateString }
  ) =>
    this.post<
      BidderCampaignBidderStat[]
    >`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/bidder-stat/`(body)

  getFeedBidDistribution = (
    request: BidderCampaignBidDistributionRequest,
    abortSignal?: AbortSignal
  ) =>
    this.post<
      BidderCampaignBidDistribution[]
    >`/bidder/feed/${this.currentMall.code}/feed-positions/`(request, {
      signal: abortSignal,
    })

  getBidderCampaignBidPrediction = (
    apiKeyId: number,
    campaignId: number,
    request: BidderCampaignBidDistributionRequest,
    targetPositions: number[],
    abortSignal?: AbortSignal
  ) =>
    this.post<
      BidderCampaignBidPrediction[]
    >`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/bid-predict/`(
      {
        ...request,
        targetPositions,
      },
      { signal: abortSignal }
    )

  getBidderRegions = () => this.get<BidderRegion[]>`/bidder/region/${this.currentMall.code}/list/`

  getFeedProductCurrentPosition = (
    request: BidderCampaignBidDistributionRequest & { sku: string }
  ) =>
    this
      .post<BidderProductCurrentPosition>`/bidder/feed/${this.currentMall.code}/product-position/`(
      request
    )

  syncBidderCampaignList = () =>
    this.post<BidderSyncResponse>`/bidder/campaign/${this.currentMall.code}/sync-all/`({})

  syncBidderCampaign = (apiKeyId: number, campaignId: number) =>
    this
      .post<BidderSyncResponse>`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/sync/`(
      {}
    )

  getBidderCampaignBudgetReplenishment = (campaignId: number) =>
    this
      .get<BidderCampaignBudgetReplenishment>`/bidder/campaign/${this.currentMall.code}/${campaignId}/budget/replenishment/settings/`

  updateBidderCampaignBudgetReplenishment = (
    campaignId: number,
    request: BidderCampaignBudgetReplenishmentUpdate
  ) =>
    this
      .post<BidderCampaignBudgetReplenishment>`/bidder/campaign/${this.currentMall.code}/${campaignId}/budget/replenishment/settings/update/`(
      request
    )

  getBidderCampaignBudgetReplenishmentLog = (campaignId: number) =>
    this.get<
      ListingDTO<BidderCampaignBudgetReplenishmentLog>
    >`/bidder/campaign/${this.currentMall.code}/${campaignId}/budget/replenishment/logs/`

  //
  //
  // BUSINESS SUPPLY RECOMMENDATION METHODS
  //

  getSupplyRecommendation = (request: SupplyRecommendationRequest) =>
    this
      .post<SupplyRecommendationReport>`/mall-analytics/supply/${this.currentMall.code}/recommendation/`(
      request
    )

  getSupplyRecommendationSettings = () =>
    this
      .get<SupplyRecommendationSettings>`/mall-analytics/supply/${this.currentMall.code}/settings/`

  getSupplyRecommendationAvailableSettings = () =>
    this
      .get<SupplyRecommendationAvailableSettings>`/mall-analytics/supply/${this.currentMall.code}/filters/`

  //
  //
  // BUSINESS SUPPLY BOOKING METHODS
  //

  startCreatingSupplyBookingAccount = (apiKeyId: number, phone: string) =>
    this.post`/supply-booking/account/${this.currentMall.code}/${apiKeyId}/start-create/`({ phone })

  sendSupplyBookingAuthCode = (apiKeyId: number, phone: string, authCode: string) =>
    this.post<{
      needSecondFactor: boolean
    }>`/supply-booking/account/${this.currentMall.code}/${apiKeyId}/send-auth-code/`({
      phone,
      authCode,
    })

  sendSupplyBookingSecondFactorAuthCode = (apiKeyId: number, phone: string, authCode: string) =>
    this.post`/supply-booking/account/${this.currentMall.code}/${apiKeyId}/send-second-auth-code/`({
      phone,
      authCode,
    })

  disconnectSupplyBookingAccount = (apiKeyId: number) =>
    this.post`/supply-booking/account/${this.currentMall.code}/${apiKeyId}/drop-session/`({})

  getSupplyBookingOrder = (orderId: number) =>
    this.get<SupplyBookingOrder>`/supply-booking/orders/${this.currentMall.code}/${orderId}/info/`

  getSupplyBookingOrdersList = (apiKeyId?: number) =>
    this.post<{
      items: SupplyBookingOrder[]
      lastSyncedAt: ISO8601DateString
    }>`/supply-booking/orders/${this.currentMall.code}/list/`({
      marketplaceAccountId: apiKeyId ?? null,
    })

  supplyBookingSyncAll = () =>
    this.post<void>`/supply-booking/orders/${this.currentMall.code}/sync-all/`({})

  planSupplyBookingOrder = (
    apiKeyId: number,
    orderId: number,
    request: SupplyBookingOrderPlanRequest
  ) =>
    this.post`/supply-booking/orders/${this.currentMall.code}/${apiKeyId}/${orderId}/plan/`(request)

  cancelSupplyBookingOrder = (apiKeyId: number, orderId: number) =>
    this.post`/supply-booking/orders/${this.currentMall.code}/${apiKeyId}/${orderId}/cancel/`({})

  //
  //
  // BUSINESS REPRICER METHODS
  //

  addRepricer = (request: RepricerAddRequest) =>
    this.post`/repricer/${this.currentMall.code}/add/`(request)

  updateRepricer = (repricerId: number, request: RepricerUpdateRequest) =>
    this.post`/repricer/${this.currentMall.code}/${repricerId}/update/`(request)

  deleteRepricer = (repricerId: number) =>
    this.post`/repricer/${this.currentMall.code}/${repricerId}/delete/`({})

  enableRepricer = (repricerId: number) =>
    this.post`/repricer/${this.currentMall.code}/${repricerId}/enable/`({})

  disableRepricer = (repricerId: number) =>
    this.post`/repricer/${this.currentMall.code}/${repricerId}/disable/`({})

  getRepricer = (repricerId: number) =>
    this.get<RepricerListItem>`/repricer/${this.currentMall.code}/${repricerId}/`

  getRepricerList = (filter: RepricerListFilter, abortSignal?: AbortSignal) =>
    this.post<ListingDTO<RepricerListItem>>`/repricer/${this.currentMall.code}/list/`(filter, {
      signal: abortSignal,
    })

  getRepricerCharts = (repricerId: number, request: RepricerGetChangesRequest) =>
    this.post<RepricerChartData>`/repricer/${this.currentMall.code}/${repricerId}/charts/`(request)

  getRepricerChangelog = (repricerId: number, request: RepricerGetChangesRequest) =>
    this.post<RepricerChangelog>`/repricer/${this.currentMall.code}/${repricerId}/changelog/`(
      request
    )

  getRepricerProductsInfo = (request: RepricerGetProductsInfoRequest) =>
    this.post<RepricerProductInfo[]>`/repricer/products/${this.currentMall.code}/list/`(request)

  //
  //
  // WB COMMISSIONS DATA METHODS
  //

  getMarketplaceCommissions = () =>
    this.get<
      MarketplaceCommission[]
    >`/mall-analytics/unit-economics/${this.currentMall.code}/commissions/`

  getWarehouseCoefficients = () =>
    this.get<
      WarehouseCoefficient[]
    >`/mall-analytics/unit-economics/${this.currentMall.code}/warehouse-coefficients/`

  //
  //
  // AUTH METHODS
  //

  private authValidateStatus = (status: number) =>
    (status >= 200 && status < 300) || status === 400 || status === 429

  registerUser = (user: NewUser) =>
    this.post<RegisterResponse>`/register/`(user, {
      validateStatus: (status) => this.authValidateStatus(status),
    })

  registerSendAuthCode = (phone: string) =>
    this.post<RegisterSendAuthCodeResponse>`/register/send-auth-code/`(
      {
        phone,
      },
      {
        validateStatus: (status) => this.authValidateStatus(status),
      }
    )

  logIn = (loginData: LoginData) =>
    this.post<LoginResponse>`/auth/login/`(loginData, {
      validateStatus: (status) => this.authValidateStatus(status),
    })

  logInSubmitAuthCode = (authCode: string) =>
    this.post<LoginSubmitAuthCodeResponse>`/auth/two-factor/`(
      { authCode },
      {
        validateStatus: (status) => this.authValidateStatus(status),
      }
    )

  recoverySendAuthCode = (phone: string) =>
    this.post<RecoverySendAuthCodeResponse>`/recovery/send-reset-code/`(
      { phone },
      {
        validateStatus: (status) => this.authValidateStatus(status),
      }
    )

  recoveryResetPassword = (phone: string, authCode: string) =>
    this.post<RecoveryResetPasswordResponse>`/recovery/reset-password/`(
      { authCode, phone },
      {
        validateStatus: (status) => this.authValidateStatus(status),
      }
    )

  getPromoCodeSignup = (code: string) =>
    this.get<PromoCode | []>`/register/promo-code/?promoCode=${code}`

  getPromoCodeSubscription = (code: string) =>
    this.get<PromoCode | []>`/subscription/promo-code/?promoCode=${code}`

  getDefaultTrialDaysSignup = () => this.get<number>`/register/default-trial-days/`

  getDefaultTrialDaysSubscription = () => this.get<number>`/subscription/default-trial-days/`

  testEmail = (email: string) => this.post<'taken' | 'available'>`/register/test-email/`({ email })

  testPhone = (phone: string) => this.post<'taken' | 'available'>`/register/test-phone/`({ phone })

  // Landing methods

  requestDemo = (request: CompanyContactRequest) =>
    this.post<{ status: 'success' }>`/landing/demo/`({ ...request })

  requestInstallment = (request: PersonContactRequest) =>
    this.post<{ status: 'success' | 'error' }>`/landing/installment/`(request)

  requestConsulting = (request: PersonContactRequest) =>
    this.post<{ status: 'success' | 'error' }>`/landing/consulting/`(request)

  requestElevenEleven = (request: PersonContactRequest) =>
    this.post<{ status: 'success' | 'error' }>`/landing/eleven-eleven/`(request)

  getSellematicsArticles = (page: number, pageSize: number, lang: 'ru' | 'en') =>
    this.get<BLArticleList>`/articles/list/${lang}/?page=${page}&pageSize=${pageSize}`

  getSellematicsArticleBySlug = (slug: string) => this.get<BLArticle>`/articles/detail/${slug}/`

  // AI methods

  getAiFeatureTimeout = (mall: string, feature: AiFeatureName) =>
    this.post<{ seconds: number }>`/ai/${mall}/allow/${feature}/`({})

  generateProductDescription = (options: {
    productName: string
    keywords: string[]
    mall: string
    marketplace: string
    language: 'en' | 'ru'
  }) => this.post<{ completion: string; seconds: number }>`/generate-product-description/`(options)

  // SEO-optimization

  getSimilarProductsSearchQueries = (productId: number) =>
    this.get<
      Feed[]
    >`/seo-optimization/${this.currentMall.code}/product/${productId}/search-queries/`

  startSimilarProductsSearchQueriesExport = (productId: number) =>
    this
      .post<StartExportResponse>`/seo-optimization/${this.currentMall.code}/product/${productId}/search-queries/export/`(
      {}
    )

  trackPrmEvent = () => this.get`/fe083d426e72af1023692fea8d533fbf/`

  trackActionpayActionClient = (action: ActionpayAction, actionId: string, price: number) =>
    this.post`/bc8b7e7eba0850d50c46d497dba51ae0/`({
      action: actionpayGoalIds[action],
      actionId,
      price,
    })

  // dataset labeling

  getDatasetLabelingProducts = () =>
    this.get<number[]>`/season/${this.currentMall.code}/list/?limit=1`

  saveDatasetLabelingProductTags = (productId: number, tagIds: number[]) =>
    this.post<[]>`/season/${this.currentMall.code}/${productId}/save/`({ ids: tagIds })

  // External advertising

  getExternalAdvertisingProductPublicationsList = (
    productId: number,
    filter: ExternalAdvertisingPublicationListFilter
  ) =>
    this.post<
      ListingDTO<ExternalAdvertisingPublication> & { lastSearchedAt: ISO8601DateString }
    >`/external-advertising/publications/${this.currentMall.code}/${productId}/list/`(filter)

  getExternalAdvertisingPublicationCount = (productsIds: number[]) =>
    this.post<
      ExternalAdvertisingPublicationCount[]
    >`/external-advertising/publications/${this.currentMall.code}/counts/`({
      productsIds,
    })

  getExternalAdvertisingSearchList = (filter: ExternalAdvertisingSearchListFilter) =>
    this.post<
      ListingDTO<ExternalAdvertisingSearch>
    >`/external-advertising/search/${this.currentMall.code}/list/`(filter)

  createExternalAdvertisingSearch = (productId: number) =>
    this.post<
      {} | { status: 'AttemptsLimitReached'; seconds: number }
    >`/external-advertising/search/${this.currentMall.code}/${productId}/create/`(
      {},
      {
        validateStatus: (status) => (status >= 200 && status < 300) || status === 400,
      }
    )

  createExternalAdvertisingExtraSearch = (productId: number) =>
    this.post<
      {} | { error: 'NoExtraSearchesAvailable' }
    >`/external-advertising/search/${this.currentMall.code}/${productId}/extra/create/`(
      {},
      {
        validateStatus: (status) => (status >= 200 && status < 300) || status === 400,
      }
    )
}
