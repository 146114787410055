import { FC } from 'lib/component-utils'
import { linkEvents, navigationEvents } from 'lib/linkEvents'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

const InterceptRouter: FC = () => {
  const router = useRouter()

  useEffect(() => {
    const handler = (url: string) => {
      let nextStateData: object = { as: url }
      const shouldContinue = navigationEvents.registerEvent({
        type: 'firstTime',
        href: url,
        state: history.state,
        addToState(data) {
          nextStateData = { ...nextStateData, ...data }
        },
      })

      if (!shouldContinue) {
        history.pushState(nextStateData, '', url)
      }

      return shouldContinue
    }

    const token = linkEvents.onNavigation(handler)

    return () => linkEvents.offNavigation(token)
  }, [linkEvents, navigationEvents])

  useEffect(() => {
    const listener = (event: PopStateEvent) => {
      if (!event.state) {
        return
      }

      const isNextEvent = Boolean(event.state.__N)
      const options = Object.keys(event.state.options ?? {})
      const beforePopStateWillIgnoreEvent =
        options.includes('locale') && options.includes('_shouldResolveHref')

      if (isNextEvent && !beforePopStateWillIgnoreEvent) {
        return
      }

      const shouldContinue = navigationEvents.registerEvent({
        type: 'popState',
        href: event.state.as,
        state: event.state,
      })

      if (shouldContinue) {
        router.replace(event.state.as)
        return false
      }

      return shouldContinue
    }
    window.addEventListener('popstate', listener)

    return () => window.removeEventListener('popstate', listener)
  }, [navigationEvents])

  useEffect(() => {
    router.beforePopState((state) => {
      const shouldContinue = navigationEvents.registerEvent({
        type: 'popState',
        href: state.as,
        state: state,
      })

      return shouldContinue
    })

    return () => router.beforePopState(() => true)
  }, [router, navigationEvents])

  return null
}

export default InterceptRouter
