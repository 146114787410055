import { FC } from 'lib/component-utils'
import { CheckCircleIcon, ExclamationIcon, InformationCircleIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import { motion } from 'motion/react'
import { useIntl } from 'lib/intl-utils'
import type { Notification } from 'lib/atoms'
import { notificationsAtom } from 'lib/atoms'
import { useSetAtom } from 'jotai'

type Props = { notification: Notification }

const Notification: FC<Props> = ({
  notification: { id, type, title, message, primaryLink, primaryButton, secondaryButton },
}) => {
  const { t } = useIntl()
  const updateNotificationState = useSetAtom(notificationsAtom)

  if (primaryButton && primaryLink) {
    throw new Error("Can't have both primary button and link in a notification")
  }

  let icon: JSX.Element
  switch (type) {
    case 'error':
      icon = <ExclamationIcon className="w-5 h-5 text-red-500" />
      break
    case 'success':
      icon = <CheckCircleIcon className="w-5 h-5 text-green-500" />
      break
    case 'info':
      icon = <InformationCircleIcon className="w-5 h-5 text-blue-500" />
      break
    default:
      icon = <InformationCircleIcon className="w-5 h-5 text-blue-500" />
      break
  }
  return (
    <motion.div
      layout
      initial={{ x: 'calc(100% + 32px)', opacity: 1.0 }}
      animate={{ x: 0, opacity: 1.0 }}
      exit={{ x: 0, opacity: 0 }}
      transition={{
        x: { ease: 'easeOut', duration: 0.2 },
        opacity: { ease: 'easeIn' },
        duration: 0.2,
      }}
      className="w-full max-w-sm overflow-hidden rounded-lg shadow-lg pointer-events-auto bg-white/70 backdrop-blur-xl backdrop-saturate-200 ring-1 ring-black/5"
    >
      <div className="p-4">
        <div className="flex items-start">
          {icon}
          <div className="flex-1 w-0 ml-3">
            <p className="text-sm font-medium text-gray-900">{title}</p>
            {message && <p className="mt-1 text-sm text-gray-500 whitespace-pre-line">{message}</p>}
            {(primaryLink || primaryButton || secondaryButton) && (
              <div className="flex mt-3 space-x-7">
                {primaryLink && (
                  <a
                    {...primaryLink.props}
                    className="text-sm font-medium rounded-md text-primary-600 hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    {primaryLink.title}
                  </a>
                )}
                {primaryButton && (
                  <button
                    type="button"
                    className="text-sm font-medium rounded-md text-primary-600 hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    onClick={primaryButton.action}
                  >
                    {primaryButton.title}
                  </button>
                )}
                {secondaryButton && (
                  <button
                    type="button"
                    className="text-sm font-medium text-gray-700 rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    onClick={secondaryButton.action}
                  >
                    {secondaryButton.title}
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="flex flex-shrink-0 ml-4">
            <button
              type="button"
              className="inline-flex text-gray-400 rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              onClick={() => updateNotificationState((s) => s.filter((e) => e.id !== id))}
            >
              <span className="sr-only">{t`close`}</span>
              <XIcon className="w-5 h-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default Notification
